/**
 * Default renderer: <td>, <th>
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IDefaultRenderer } from '../types';

/**
 * – Don't render a <th> with no content (for headerless tables)
 */
export function tableCell(): IDefaultRenderer {
    return (table_cell_props: any): ReactElement | null => {
        const { isHeader: is_header, children } = table_cell_props;

        if (is_header) {
            if (!children[0] || !children[0].props.value?.trim()) {
                return null;
            }

            return <th>{children}</th>;
        }

        return <td>{children}</td>;
    };
}

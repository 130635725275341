/**
 * Component: Markdown-to-React renderer
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { IDocumentationSidebarProps } from '../../../types';
import { IHTMLToReactNode, ICustomRenderers, IDefaultRenderers } from './types';
import * as CustomRenderers from './custom_renderers';
import * as DefaultRenderers from './default_renderers';

const HtmlToReact = require('html-to-react');
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const htmlParser = require('react-markdown/plugins/html-parser');

/**  Interfaces  */
type IMarkdownConstructor = Pick<
    IDocumentationSidebarProps,
    'ast' | 'INTERNAL_OAUTH2_DOMAIN' | 'API_DOMAIN' | 'navigateToDocumentationPage' | 'url_prefix'
>;

/**  Exports  */
export class Markdown {
    ast: IDocumentationSidebarProps['ast'];
    custom_renderers: ICustomRenderers;
    default_renderers: IDefaultRenderers;
    ParseHTMLPlugin: any;

    constructor(props: IMarkdownConstructor) {
        const {
            ast,
            API_DOMAIN,
            INTERNAL_OAUTH2_DOMAIN,
            navigateToDocumentationPage,
            url_prefix,
        } = props;

        this.ast = ast;

        const renderChildren = this.children.bind(this);

        /**
         * @const this.custom_renderers – Renderers for custom HTML tags
         * - EDITABLE for additional options
         */
        this.custom_renderers = {
            // <break />
            break: CustomRenderers.breakRenderer(),

            // <endpoint />
            endpoint: CustomRenderers.endpoint({
                ast,
                API_DOMAIN,
                INTERNAL_OAUTH2_DOMAIN,
                navigateToDocumentationPage,
                url_prefix,
            }),

            // <highlight colour="$COLOUR">$CONTENT</highlight>
            highlight: CustomRenderers.highlight({ renderChildren }),

            // <loom url="$URL" />
            loom: CustomRenderers.loom(),

            // <method type="$TYPE" />
            method: CustomRenderers.method(),

            // <schema type="$TYPE" | input="$INPUT" />
            schema: CustomRenderers.schema({
                ast,
                API_DOMAIN,
                INTERNAL_OAUTH2_DOMAIN,
                navigateToDocumentationPage,
                url_prefix,
            }),

            // <scope value="$VALUE" />
            scope: CustomRenderers.scope(),

            // <scopeblock scopes="$VALUE" />
            scopeblock: CustomRenderers.scopeblock(),

            // <smallcaps>$CONTENT</smallcaps>
            smallcaps: CustomRenderers.smallcaps(),

            // <type value="$VALUE" />
            type: CustomRenderers.typeString(),
        };

        /**
         * @const this.default_renderers – Renderers for default HTML tags
         * - See react-markdown documentation for reference
         * - https://github.com/rexxars/react-markdown#node-types
         */
        this.default_renderers = {
            code: DefaultRenderers.pre(),
            heading: DefaultRenderers.heading(),
            link: DefaultRenderers.link({
                renderChildren,
                navigateToDocumentationPage,
                url_prefix,
            }),
            paragraph: DefaultRenderers.paragraph({ renderChildren }),
            tableCell: DefaultRenderers.tableCell(),

            // blockquote
            // break
            // code
            // definition
            // delete
            // emphasis
            // heading
            // html
            // image
            // imageReference
            // inlineCode
            // link
            // linkReference
            // list
            // listItem
            // paragraph
            // parsedHtml
            // root
            // strong
            // table
            // tableBody
            // tableCell
            // tableHead
            // tableRow
            // text
            // thematicBreak
            // virtualHtml
        };

        /**
         * @const this.ParseHTMLPlugin - react-markdown HTML plugin
         * - No need to edit
         */
        this.ParseHTMLPlugin = htmlParser({
            isValidNode: () => true,
            processingInstructions: [
                // A: Custom renderers
                ...Object.keys(this.custom_renderers).map(tag => ({
                    shouldProcessNode: (node: IHTMLToReactNode): boolean => {
                        return node.name === tag;
                    },
                    replaceChildren: !!this.custom_renderers[tag].replaceChildren,
                    processNode: this.custom_renderers[tag].processNode,
                })),

                // B: Default
                {
                    shouldProcessNode: (): boolean => true,
                    processNode: processNodeDefinitions.processDefaultNode,
                },
            ],
        });
    }

    children({ components }: { components: Array<ReactElement | string> }): ReactElement {
        return (
            <>
                {[...components].map(
                    (child, i): ReactElement => {
                        if (typeof child === 'string') {
                            return this.render({ src: child, nested: true });
                        }

                        return child;
                    }
                )}
            </>
        );
    }

    render({ src, nested = false }: { src: string; nested?: boolean }): ReactElement {
        //  If nested, render default <p> as <span>
        const nested_renderers = {
            ...this.default_renderers,
            paragraph: (p_props: any): ReactElement => {
                return <span>{this.children({ components: p_props.children })}</span>;
            },
        };

        return (
            <ReactMarkdown
                key={src}
                source={src}
                escapeHtml={false}
                astPlugins={[this.ParseHTMLPlugin]}
                renderers={nested ? nested_renderers : this.default_renderers}
            />
        );
    }
}

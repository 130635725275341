/**
 * Component: Sidebar header
 */
import React, { ReactElement } from 'react';
import { API_LAST_UPDATED, API_VERSION } from '../../../common/globals';
import { IDocumentationSidebarProps } from '../../../types';

export function SidebarHeader(props: IDocumentationSidebarProps): ReactElement {
    return (
        <div className='sidebar--header'>
            <img
                alt=''
                src='https://cdn.nicejob.co/assets/nj-star-v2.png'
                className='sidebar--header--img'
            />
            <div className='sidebar--header--content'>
                <div className='sidebar--header--title'>
                    <span className='sidebar--header--title--name'>NiceJob API</span>
                    <span className='sidebar--button'>{API_VERSION}</span>
                </div>
                <p className='sidebar--header--updated'>{API_LAST_UPDATED}</p>
            </div>
        </div>
    );
}

/**
 * Custom renderer: <smallcaps />
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IHTMLToReactNode, ICustomRenderer } from '../types';

// <smallcaps>$CONTENT</smallcaps>
export function smallcaps(): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement => {
            return <span className='smallcaps'>{children}</span>;
        },
    };
}

/**
 * developer-route globals
 */
import path from 'path';
import { Online } from '@nicejob/nicejob-toolbox';

export const DOCUMENTATION_BODY_CLASS = 'documentation-body';
export const DOCS_PATH = '/docs';
export const PORTAL_PATH = '/docs/credentials';
export const LOGIN_PATH = '/docs/login';
export const LOGOUT_PATH = '/docs/logout';
export const PORTAL_UPDATE_PATH = '/docs/credentials';
export const PORTAL_INVITE_PATH = '/docs/invite';
export const API_VERSION = 'v2-alpha';
export const API_LAST_UPDATED = '14 Jan. 2021, 3:00pm PT';

export const INDEX_FILE_PATH = path.join(
    process.cwd(),
    Online ? '/pages/index.html' : '/dist/pages/index.html'
);

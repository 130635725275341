/**
 * Custom renderer: <endpoint />
 */
/* eslint-disable react/display-name */
import React, { CSSProperties, ReactElement, useState } from 'react';
import { Markdown as MarkdownClass } from '..';
import { ISchemataModuleASTTypeDefinition } from '../../../../../../../types/modules.types';
import { IDocumentationSidebarProps } from '../../../../types';
import { ICustomRenderer, IHTMLToReactNode } from '../types';
import { SchemaComponent } from './schema';

/**  Types  */
interface IEndpointComponentProps
    extends Pick<
        IDocumentationSidebarProps,
        | 'ast'
        | 'INTERNAL_OAUTH2_DOMAIN'
        | 'API_DOMAIN'
        | 'navigateToDocumentationPage'
        | 'url_prefix'
    > {
    method: string;
    url: string;
    input?: string;
    query?: string;
    oauth?: string;
    title?: string;
    description?: string;
    omit_fields: string;
}

interface IParseEndpointSchemaResponse {
    parent_type?: string;
    schema_type: 'INPUT' | 'QUERY' | null;
    input_schema: ISchemataModuleASTTypeDefinition;
    path_field_schema: ISchemataModuleASTTypeDefinition;
    query_field_schema: ISchemataModuleASTTypeDefinition;
    has_cursor: boolean;
}

type OAuthRequestType = 'authorization' | 'refresh_token' | 'access_token' | 'client_credentials';

/**  Globals  */
const PATH_VAR_PATTERN = /\/:(.+?)($|\/)/g;

const OAUTH_REQUESTS: Record<
    OAuthRequestType,
    {
        request_query?: Record<string, string>;
        response_query?: Record<string, string>;
        request_body?: Record<string, string>;
        response_body?: Record<string, string>;
        omit_authorization_header?: boolean;
    }
> = {
    authorization: {
        omit_authorization_header: true,
        request_query: {
            response_type: 'code',
            client_id: '$CLIENT_ID',
            redirect_uri: '$REDIRECT_URI',
            scope: '$SCOPES',
            state: '$STATE_KEY',
        },
        response_query: {
            code: `<AUTHORIZATION CODE>`,
            state: `<STATE KEY>`,
        },
    },
    refresh_token: {
        omit_authorization_header: true,
        request_body: {
            grant_type: `authorization_code`,
            code: `$AUTHORIZATION_CODE`,
            client_id: `$CLIENT_ID`,
            client_secret: `$CLIENT_SECRET`,
            redirect_uri: `$REDIRECT_URI`,
        },
        response_body: {
            token_type: `Bearer`,
            refresh_token: `<REFRESH TOKEN>`,
            access_token: `<ACCESS TOKEN>`,
            expires_in: `<ACCESS TOKEN EXPIRY (seconds)>`,
        },
    },
    access_token: {
        omit_authorization_header: true,
        request_body: {
            grant_type: `refresh_token`,
            refresh_token: `$REFRESH_TOKEN`,
            client_id: `$CLIENT_ID`,
            client_secret: `$CLIENT_SECRET`,
        },
        response_body: {
            token_type: `Bearer`,
            refresh_token: `<REFRESH TOKEN>`,
            access_token: `<ACCESS TOKEN>`,
            expires_in: `<ACCESS TOKEN EXPIRY (seconds)>`,
        },
    },
    client_credentials: {
        omit_authorization_header: true,
        request_body: {
            grant_type: `client_credentials`,
            client_id: `$CLIENT_ID`,
            client_secret: `$CLIENT_SECRET`,
        },
        response_body: {
            token_type: `Bearer`,
            access_token: `<ACCESS TOKEN>`,
            expires_in: `<ACCESS TOKEN EXPIRY (seconds)>`,
        },
    },
};

/**
 * Renderer
 */
export function endpoint(
    props: Pick<
        IDocumentationSidebarProps,
        | 'ast'
        | 'INTERNAL_OAUTH2_DOMAIN'
        | 'API_DOMAIN'
        | 'navigateToDocumentationPage'
        | 'url_prefix'
    >
): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement | null => {
            const {
                method,
                url: endpoint_url,
                input,
                query,
                oauth,
                title,
                description,
                omit_fields = '',
            } = node.attribs;

            return (
                <EndpointComponent
                    ast={props.ast}
                    API_DOMAIN={props.API_DOMAIN}
                    INTERNAL_OAUTH2_DOMAIN={props.INTERNAL_OAUTH2_DOMAIN}
                    navigateToDocumentationPage={props.navigateToDocumentationPage}
                    url_prefix={props.url_prefix}
                    input={input}
                    query={query}
                    method={method}
                    oauth={oauth}
                    title={title}
                    description={description}
                    url={endpoint_url}
                    omit_fields={omit_fields}
                />
            );
        },
    };
}

/**
 * Component: Endpoint
 */
function EndpointComponent(props: IEndpointComponentProps): ReactElement | null {
    const {
        ast,
        url,
        method,
        input,
        query,
        oauth: input_oauth,
        omit_fields,
        title,
        description,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    } = props;

    const oauth = input_oauth as OAuthRequestType;

    const Markdown = new MarkdownClass({
        ast,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    });

    //  State & methods
    const [is_curl_open, toggleCurl] = useState(false);
    const [is_response_open, toggleResponse] = useState(false);

    //  HTTP method
    const method_uc = method.toUpperCase();
    const method_lc = method.toLowerCase();

    //  curl options
    const options: Array<string> = [];

    const request_query_params: Record<string, string> =
        (oauth && OAUTH_REQUESTS[oauth]?.request_query) || {};
    const response_query_params: Record<string, string> =
        (oauth && OAUTH_REQUESTS[oauth]?.response_query) || {};

    const request_body_params: Record<string, string> =
        (oauth && OAUTH_REQUESTS[oauth]?.request_body) || {};
    const response_body_params: Record<string, string> =
        (oauth && OAUTH_REQUESTS[oauth]?.response_body) || {};

    if (method_uc !== 'REDIRECT') {
        if (!oauth || !OAUTH_REQUESTS[oauth]?.omit_authorization_header) {
            options.push(`-H "Authorization: Bearer $ACCESS_TOKEN"`);
        }

        if (method_uc !== 'GET') {
            options.splice(0, 0, `-X ${method_uc}`);
            options.push(`-H "Content-Type: application/json"`);

            //  Push generic data body for all but OAuth requests
            if (!oauth) {
                options.push(`-d '<STRINGIFIED REQUEST BODY JSON>'`);
            }
        }
    }

    //  Endpoint URL
    const endpoint_url = parseEndpointUrl(props);

    //  Styles
    const curl_arrow_style: CSSProperties = is_curl_open
        ? {
              transform: 'rotate(180deg)',
              transformOrigin: '50% 50%',
          }
        : {};

    const response_arrow_style: CSSProperties = is_response_open
        ? {
              transform: 'rotate(180deg)',
              transformOrigin: '50% 50%',
          }
        : {};

    //  Cursor
    const { has_cursor } = parseEndpointSchema(props);

    /**
     * n: Render
     */
    return (
        <>
            {/*  Endpoint data  */}
            <div className={`highlight highlight--curl`}>
                {/*  Endpoint  */}
                <div
                    className={`curl--header curl--header--request curl--header--${method_lc}`}
                    onClick={(): void => toggleCurl(!is_curl_open)}
                >
                    <div className={`curl--dest`}>
                        <code className={`curl--header--method curl--method--${method_lc}`}>
                            {method_uc}
                        </code>
                        <code className={`curl--header--url`}>{endpoint_url}</code>
                    </div>
                    <div
                        style={curl_arrow_style}
                        className={`curl--header--open curl--header--open--request`}
                    >
                        ▾
                    </div>
                </div>

                {/*  cURL sample  */}
                {is_curl_open && method_uc === 'REDIRECT' ? (
                    <div className={`curl--body curl--body--request`}>
                        <code className='curl--body--init'>301 REDIRECT</code>
                        <code className='curl--body--init'>
                            {endpoint_url} {Object.keys(request_query_params).length ? ` \\` : ''}
                        </code>
                        {Object.keys(request_query_params).map((k, index) => (
                            <code key={k}>
                                {index ? '&' : '?'}
                                {`${k}=${request_query_params[k]}`}
                                {index < Object.keys(request_query_params).length - 1 ? ` \\` : ''}
                            </code>
                        ))}
                    </div>
                ) : is_curl_open ? (
                    <div className={`curl--body curl--body--request`}>
                        <code className={`curl--body--init`}>$ curl \</code>
                        {options.map(opt => (
                            <code key={opt.replace(/ /g, '-')}>{`${opt} \\`}</code>
                        ))}
                        {Object.keys(request_body_params).map((k, index) => (
                            <code key={k}>{`-d ${k}=${request_body_params[k]} \\`}</code>
                        ))}
                        <code>{endpoint_url}</code>
                    </div>
                ) : null}

                {/*  Response format  */}
                <div
                    className={`curl--header curl--header--response`}
                    onClick={(): void => toggleResponse(!is_response_open)}
                >
                    <span className={`curl--dest`}>Response:</span>
                    <div
                        style={response_arrow_style}
                        className={`curl--header--open curl--header--open--response`}
                    >
                        ▾
                    </div>
                </div>

                {is_response_open && method_uc === 'REDIRECT' ? (
                    //  REDIRECT response
                    <div className={`curl--body curl--body--response`}>
                        <code className='curl--body--init'>301 REDIRECT</code>
                        <code className='curl--body--init'>
                            {`$REDIRECT_URI`}{' '}
                            {Object.keys(response_query_params).length ? ` \\` : ''}
                        </code>
                        {Object.keys(response_query_params).map((k, index) => (
                            <code key={k}>
                                {index ? '&' : '?'}
                                {`${k}=${response_query_params[k]}`}
                                {index < Object.keys(response_query_params).length - 1 ? ` \\` : ''}
                            </code>
                        ))}
                    </div>
                ) : is_response_open && Object.keys(response_body_params).length ? (
                    //  JSON response (OAuth)
                    <div className={`curl--body curl--body--response`}>
                        <code className='curl--body--init'>{`{`}</code>
                        {Object.keys(response_body_params).map((k, index) => (
                            <code key={k}>
                                {`${k}: ${response_body_params[k]}`}
                                {index < Object.keys(response_body_params).length - 1 ? `,` : ''}
                            </code>
                        ))}
                        <code className='curl--body--init'>{`}`}</code>
                    </div>
                ) : is_response_open ? (
                    //  JSON response
                    <div className={`curl--body curl--body--response`}>
                        <code className='curl--body--init'>{`{`}</code>
                        <code>{`data: <RESPONSE DATA>,`}</code>
                        {has_cursor ? (
                            <code>{`cursor: <NEW PAGINATION CURSOR> | null,`}</code>
                        ) : (
                            <code>{`cursor: null,`}</code>
                        )}
                        <code>{`error: <RESPONSE ERROR> | null`}</code>
                        <code className='curl--body--init'>{`}`}</code>
                    </div>
                ) : null}

                {/*  Description  */}
                {title || description ? (
                    <div className='curl--description'>
                        {title ? <span className='smallcaps'>{title}</span> : null}
                        {description
                            ? Markdown.render({
                                  src: description,
                              })
                            : null}
                    </div>
                ) : null}
            </div>

            {/*  Input schema  */}
            {input || query ? (
                <EndpointSchema
                    method={method}
                    API_DOMAIN={API_DOMAIN}
                    INTERNAL_OAUTH2_DOMAIN={INTERNAL_OAUTH2_DOMAIN}
                    url={url}
                    input={input}
                    query={query}
                    ast={ast}
                    omit_fields={omit_fields}
                    navigateToDocumentationPage={navigateToDocumentationPage}
                    url_prefix={url_prefix}
                />
            ) : null}
        </>
    );
}

/**
 * Component: Endpoint schema
 */
function EndpointSchema(
    props: Pick<
        IEndpointComponentProps,
        | 'method'
        | 'url'
        | 'input'
        | 'query'
        | 'ast'
        | 'omit_fields'
        | 'API_DOMAIN'
        | 'INTERNAL_OAUTH2_DOMAIN'
        | 'navigateToDocumentationPage'
        | 'url_prefix'
    >
): ReactElement {
    const {
        method,
        ast,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    } = props;

    /**
     * 1: Get input schema
     */
    const {
        parent_type,
        input_schema,
        path_field_schema,
        query_field_schema,
    } = parseEndpointSchema(props);

    /**
     * n: Render
     */
    const Markdown = new MarkdownClass({
        ast,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    });

    return (
        <>
            {/* Path parameters: Required */}
            {Object.keys(path_field_schema).length ? (
                <>
                    {Markdown.render({
                        src: `#### Required path parameters`,
                    })}
                    <SchemaComponent
                        ast={ast}
                        API_DOMAIN={API_DOMAIN}
                        INTERNAL_OAUTH2_DOMAIN={INTERNAL_OAUTH2_DOMAIN}
                        ast_schema={path_field_schema}
                        parent_type={parent_type}
                        is_input={false}
                        navigateToDocumentationPage={navigateToDocumentationPage}
                        url_prefix={url_prefix}
                    />
                </>
            ) : null}

            {/* Path parameters: Optional */}
            {Object.keys(query_field_schema).length ? (
                <>
                    {Markdown.render({
                        src: `#### Optional query parameters`,
                    })}
                    <SchemaComponent
                        ast={ast}
                        API_DOMAIN={API_DOMAIN}
                        INTERNAL_OAUTH2_DOMAIN={INTERNAL_OAUTH2_DOMAIN}
                        ast_schema={query_field_schema}
                        parent_type={parent_type}
                        is_input={true}
                        navigateToDocumentationPage={navigateToDocumentationPage}
                        url_prefix={url_prefix}
                    />
                </>
            ) : null}

            {/* Body */}
            {Object.keys(input_schema).length ? (
                <>
                    {Markdown.render({
                        src: `#### Request body`,
                    })}
                    <SchemaComponent
                        ast={ast}
                        API_DOMAIN={API_DOMAIN}
                        INTERNAL_OAUTH2_DOMAIN={INTERNAL_OAUTH2_DOMAIN}
                        ast_schema={input_schema}
                        parent_type={parent_type}
                        is_input={true}
                        navigateToDocumentationPage={navigateToDocumentationPage}
                        url_prefix={url_prefix}
                    />
                </>
            ) : null}
        </>
    );
}

/**
 * Parse schema
 */
function parseEndpointSchema(
    props: Pick<
        IEndpointComponentProps,
        'method' | 'url' | 'input' | 'query' | 'ast' | 'omit_fields'
    >
): IParseEndpointSchemaResponse {
    const { method, url, input, query, ast, omit_fields: input_omit_fields = '' } = props;

    /**
     * 1: Get input schema
     */

    //  Schema (clone)
    const input_schema_i = ((input && ast.input[input]) ||
        // @ts-ignore
        (query && ast.type.Query[query] && ast.type.Query[query].args) ||
        {}) as ISchemataModuleASTTypeDefinition;

    const input_schema = clone(input_schema_i);

    //  Type
    const parent_type = input || query;
    const schema_type =
        input && ast.input[input] ? 'INPUT' : query && ast.type.Query[query] ? 'QUERY' : null;

    //  Prune company_id from Query args (comes in via authentication)
    if (schema_type === 'QUERY') {
        delete input_schema.company_id;
    }

    /**
     * 2: Parse
     */

    //  Fields to omit
    const fields_to_omit = input_omit_fields
        .split(',')
        .map(p => p.trim())
        .filter(x => x);

    for (const field of fields_to_omit) {
        delete input_schema[field];
    }

    //  Path parameters (required)
    const path_fields: Array<string> = [];
    const path_field_schema: ISchemataModuleASTTypeDefinition = {};

    // const endpoint_url = parseEndpointUrl(props);

    let url_matches = PATH_VAR_PATTERN.exec(url);
    while (url_matches) {
        const path_var = url_matches[1];
        path_fields.push(path_var);

        url_matches = PATH_VAR_PATTERN.exec(url);
    }

    //  --> Schema
    for (const field of path_fields) {
        if (input_schema && input_schema[field]) {
            path_field_schema[field] = { ...input_schema[field], isRequired: true };
            delete input_schema[field];
        }
    }

    //  Query parameters (optional)
    const query_fields: Array<string> = [];
    const query_field_schema: ISchemataModuleASTTypeDefinition = {};

    //  --> Schema
    for (const field of query_fields) {
        if (input_schema && input_schema[field]) {
            query_field_schema[field] = { ...input_schema[field], isRequired: true };
            delete input_schema[field];
        }
    }

    //  --> GET? Append remaining to optional
    if (method.toUpperCase() === 'GET') {
        for (const field in input_schema) {
            query_field_schema[field] = input_schema[field];
            delete input_schema[field];
        }
    }

    //  --> cursor: Append to optional path, always
    const has_cursor = !!input_schema.cursor;
    if (has_cursor) {
        query_field_schema.cursor = { type: 'scalar', value: 'ID' };
        delete input_schema.cursor;
    }

    return {
        parent_type,
        schema_type,
        input_schema,
        path_field_schema,
        query_field_schema,
        has_cursor,
    };
}

/**
 * Endpoint URL, with query params
 */
function parseEndpointUrl(props: IEndpointComponentProps): string {
    const { url, API_DOMAIN, INTERNAL_OAUTH2_DOMAIN, oauth } = props;

    const domain = oauth ? INTERNAL_OAUTH2_DOMAIN : API_DOMAIN;
    let endpoint_url = `${domain}${url}`;

    //  Append query params
    const { query_field_schema } = parseEndpointSchema(props);
    for (const k in query_field_schema) {
        endpoint_url += endpoint_url.includes('?') ? '&' : '?';
        endpoint_url += `${k}=$${k.toUpperCase()}`;
    }

    return endpoint_url;
}

/**
 * Clone an object
 */
function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

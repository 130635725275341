import React, { ReactElement } from 'react';

export function DevAuthHeader(): ReactElement {
    return (
        <a className='oauth_header' href='/docs' style={{ display: 'block' }}>
            <img src='https://cdn.nicejob.co/nicejob_logo_v2.png' alt='NiceJob logo' />
            <span style={{ paddingRight: 0 }}>NiceJob API</span>
        </a>
    );
}

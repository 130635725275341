import React, { ReactElement, useState } from 'react';
import { ILoginPageProps } from '../../types';
import { DevForgotPasswordFooter } from './common/DevAuthFooter';
import { DevAuthHeader } from './common/DevAuthHeader';

export function LoginPage(props: ILoginPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */

    return (
        <div className='narrow'>
            <DevAuthHeader />

            <LoginPageBody {...props} />
        </div>
    );
}

function LoginPageBody(props: ILoginPageProps): ReactElement {
    const [is_loading, setIsLoading] = useState(false);

    /**
     * Set loading state when submitting the form
     */
    function onLogin(): void {
        setIsLoading(true);
    }

    return (
        <>
            <div className={`block submitted_loading ${is_loading ? 'form_submitted' : ''}`}>
                <div className='card login_card submitted_hide'>
                    <form
                        className='submit_exit submitted_disabled'
                        onSubmit={onLogin}
                        action='/docs/login'
                        method='POST'
                    >
                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='Email address'
                                type='email'
                                name='email'
                                defaultValue={props.email}
                            />
                        </div>
                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='Password'
                                type='password'
                                name='password'
                            />
                        </div>
                        <div className='row submit_row'>
                            <input
                                className='contained_button submit_input'
                                type='submit'
                                value='Log in to the NiceJob API'
                                disabled={is_loading}
                            />
                        </div>
                    </form>

                    {props.error_message && (
                        <div className='error'>
                            <strong>Login error:</strong>
                            <span>{props.error_message}</span>
                        </div>
                    )}
                </div>
            </div>

            <DevForgotPasswordFooter />
        </>
    );
}

import React, { ReactElement, useState } from 'react';
import { validatePassword as commonValidatePassword } from '../../../../../common/validation/validatePassword';
import { IResetPasswordPageProps } from '../../types';
import { DevLoginFooter } from './common/DevAuthFooter';

export function ResetPasswordPage(props: IResetPasswordPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */

    return (
        <div className='narrow'>
            <DevLoginFooter />

            <ResetPasswordPageBody {...props} />
        </div>
    );
}

function ResetPasswordPageBody(props: IResetPasswordPageProps): ReactElement {
    const [password, setPassword] = useState('');
    const [is_loading, setIsLoading] = useState(false);
    const [validation_error, setValidationError] = useState<string>();

    function validatePassword(): void {
        const { valid, message } = commonValidatePassword({ password });

        if (!valid) {
            setValidationError(message);
        } else {
            setValidationError(undefined);
        }
    }

    /**
     * Set loading state when submitting the form
     */
    function onReset(): void {
        setIsLoading(true);
    }

    return (
        <>
            <div className={`block submitted_loading ${is_loading ? 'form_submitted' : ''}`}>
                <div className='card login_card submitted_hide'>
                    <form
                        onSubmit={onReset}
                        action={`/docs/reset_password?code=${props.code}`}
                        method='POST'
                        className='submit_exit submitted_disabled'
                    >
                        <p className='invalid'>Enter your new password in the box below:</p>

                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='New password'
                                type='password'
                                name='password'
                                value={password}
                                onChange={(e): void => setPassword(e.target.value)}
                                onBlur={validatePassword}
                            />
                        </div>
                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='Confirm password'
                                type='password'
                                name='confirm_password'
                            />
                        </div>

                        <div className='row submit_row'>
                            <input
                                className='contained_button submit_input'
                                type='submit'
                                value='Submit'
                                disabled={is_loading}
                            />
                        </div>

                        <input className='hidden' type='text' name='code' value={props.code} />
                    </form>

                    {validation_error && (
                        <div className='error'>
                            <span>{validation_error}</span>
                        </div>
                    )}

                    {props.error_message && (
                        <div className='error'>
                            <strong>Error:</strong>
                            <span>{props.error_message}</span>
                        </div>
                    )}
                </div>
            </div>

            <DevLoginFooter />
        </>
    );
}

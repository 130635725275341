import React, { ReactElement } from 'react';

export function DevLoginFooter(): ReactElement {
    return (
        <div className='block submitted_hide'>
            <a className='button text_button submitted_disabled' href='/docs/login'>
                <span>Login to the NiceJob API</span>
            </a>
        </div>
    );
}

export function DevForgotPasswordFooter(): ReactElement {
    return (
        <div className='block submitted_hide' style={{ paddingTop: 0 }}>
            <a className='button text_button submitted_disabled' href='/docs/forgot_password'>
                <span>Forgot your password?</span>
            </a>
        </div>
    );
}

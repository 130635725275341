/**
 * Custom renderer: <scope />
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IHTMLToReactNode, ICustomRenderer } from '../types';

// <scope value="$VALUE" />
export function scope(): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement => {
            const { value = '', colour: input_colour, color } = node.attribs;

            const colour = input_colour || color || 'green';

            return (
                <a className={`scope scope--${colour}`} href='#scopes'>
                    {value}
                </a>
            );
        },
    };
}

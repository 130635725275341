/**
 * Default renderer: <a>
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IDefaultRenderer, ChildRenderFunction } from '../types';
import { IDocumentationSidebarProps } from '../../../../types';

/**
 * - Identify in-page header links
 */
export function link(props: {
    renderChildren: ChildRenderFunction;
    navigateToDocumentationPage: IDocumentationSidebarProps['navigateToDocumentationPage'];
    url_prefix: IDocumentationSidebarProps['url_prefix'];
}): IDefaultRenderer {
    const { renderChildren, navigateToDocumentationPage, url_prefix } = props;

    return (link_props: any): ReactElement => {
        const { href } = link_props;
        const target = href.startsWith('/') ? '_self' : '_blank';
        const rel = href.startsWith('/') ? '' : 'noopener noreferrer';

        // A: In-page link
        // B: Outbound link
        return href.startsWith('#') ? (
            <a className='local' href={href}>
                {renderChildren({ components: link_props.children })}
            </a>
        ) : href.startsWith(url_prefix) ? (
            <a href={href} onClick={navigateToDocumentationPage}>
                {renderChildren({ components: link_props.children })}
            </a>
        ) : (
            <a href={href} target={target} rel={rel}>
                {renderChildren({ components: link_props.children })}
            </a>
        );
    };
}

/**
 * Default renderer: <h1>, <h2>, etc.
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IDefaultRenderer } from '../types';
import { headerId } from '../../navigation';

/**
 * - Add `id`
 */
export function heading(): IDefaultRenderer {
    return (heading_props: any): ReactElement | null => {
        const { level, children } = heading_props;
        const value =
            (children.length &&
                children
                    // @ts-ignore
                    .map(child => child.props?.value || '')
                    // @ts-ignore
                    .filter(x => x)
                    .join(' ')) ||
            '';

        const h_props: { id?: string } = {};
        if (value) {
            h_props.id = headerId(value);
        }

        return React.createElement(`h${level}`, h_props, children);
    };
}

/**
 * Validate a URL
 * - Prefixes with protocol, if necessary
 */
const VALID_URL_PATTERN = /^(?:(?:https?):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;

export function validateURL(
    input_value: string
): {
    is_valid: boolean;
    valid_url: string | null;
} {
    const value = (input_value || '').trim();
    const is_value = !!value;

    const url = value.startsWith('http') ? value : `http://${value}`;
    const no_js = !url.includes('javascript:');

    const is_valid = is_value && no_js && VALID_URL_PATTERN.test(url);

    return { is_valid, valid_url: is_valid ? url : null };
}

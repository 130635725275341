/**
 * Custom renderer: <schema />
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { Markdown as MarkdownClass } from '..';
import { REST_GQL_PROPERTY_MAPPINGS } from '../../../../../../../common';
import {
    ISchemataASTFieldDefinition,
    ISchemataModuleASTTypeDefinition,
} from '../../../../../../../types/modules.types';
import { IDocumentationSidebarProps } from '../../../../types';
import { PropertyDescriptions } from '../../../Documentation/propertyDescriptions';
import { ICustomRenderer, IHTMLToReactNode } from '../types';

/** Globals */
const NULL_STRING = 'null';

/**  Types  */
interface ISchemaComponentProps
    extends Pick<
        IDocumentationSidebarProps,
        | 'ast'
        | 'INTERNAL_OAUTH2_DOMAIN'
        | 'API_DOMAIN'
        | 'navigateToDocumentationPage'
        | 'url_prefix'
    > {
    is_input: boolean;
    ast_schema: ISchemataModuleASTTypeDefinition;
    parent_type?: string;
}

interface IASTFieldProps {
    parent_type?: string;
    is_input: boolean;
    field: string;
    field_display: string;
    field_prefix?: string;
    field_definition: ISchemataASTFieldDefinition;
    ast_schema: ISchemataModuleASTTypeDefinition;
    no_nesting?: boolean;
    Markdown: MarkdownClass;
}

/**  Globals  */
const TYPE_MAP: {
    [type: string]: string;
} = {
    id: 'string',
    date: 'Date',
    emailaddress: 'string',
    json: 'object',
    phonenumber: 'string',
};

const DEFAULT_RESPONSE_SCHEMA: ISchemataModuleASTTypeDefinition = {
    data: {
        type: 'scalar',
        value: 'JSON',
        isRequired: false,
        // isArray: boolean,
        // defaultValue: any,

        // Schema v2
        description: `The request data, corresponding to the <smallcaps>Response data</smallcaps> descriptions accompanying each request in the documentation below.`,
        // isUnique: boolean,
    },
    cursor: {
        type: 'scalar',
        value: 'String',
        isRequired: false,
        description: `Applies to list requests only. When more results are available, a [pagination](#responses) cursor will be returned. Otherwise will be \`null\`. Use cursors to return the next page of results.`,
    },
    error: {
        type: 'scalar',
        value: 'String',
        isRequired: false,
        description: `The [aforementioned error message](#response--error).`,
    },
};

/**
 * Renderer
 * <schema type="$TYPE" | input="$INPUT" />
 */
export function schema(
    props: Pick<
        IDocumentationSidebarProps,
        | 'ast'
        | 'INTERNAL_OAUTH2_DOMAIN'
        | 'API_DOMAIN'
        | 'navigateToDocumentationPage'
        | 'url_prefix'
    >
): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement | null => {
            const {
                ast,
                API_DOMAIN,
                INTERNAL_OAUTH2_DOMAIN,
                navigateToDocumentationPage,
                url_prefix,
            } = props;
            const { type, input, query, mutation, subscription } = node.attribs;

            const ast_schema = (type === 'DEFAULT_RESPONSE'
                ? DEFAULT_RESPONSE_SCHEMA
                : type
                ? ast.type[type]
                : input
                ? ast.input[input]
                : query
                ? ast.type.Query[query]
                : mutation
                ? ast.type.Mutation[mutation]
                : subscription
                ? ast.type.Subscription[subscription]
                : null) as ISchemataModuleASTTypeDefinition | null;

            const parent_type = type || input || query || mutation || subscription;
            const is_input = !type && !!input;

            if (!ast_schema || !parent_type) {
                console.error(`schema for "${parent_type}" not found.`);

                return null;
            }

            return (
                <SchemaComponent
                    ast={ast}
                    API_DOMAIN={API_DOMAIN}
                    INTERNAL_OAUTH2_DOMAIN={INTERNAL_OAUTH2_DOMAIN}
                    ast_schema={ast_schema}
                    parent_type={parent_type}
                    is_input={is_input}
                    navigateToDocumentationPage={navigateToDocumentationPage}
                    url_prefix={url_prefix}
                />
            );
        },
    };
}

/**
 * Component: <schema />
 */
export function SchemaComponent(props: ISchemaComponentProps): ReactElement {
    const {
        ast,
        ast_schema,
        parent_type,
        is_input,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    } = props;

    const Markdown = new MarkdownClass({
        ast,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    });

    const keys = astKeys(parent_type, ast_schema);

    return (
        <ul className='entity-properties'>
            {keys.map(
                ({ key, display_value }): ReactElement => {
                    return (
                        <ASTField
                            key={key}
                            Markdown={Markdown}
                            parent_type={parent_type}
                            is_input={is_input}
                            ast_schema={ast_schema}
                            field={key}
                            field_display={display_value}
                            field_definition={ast_schema[key]}
                        />
                    );
                }
            )}
        </ul>
    );
}

/**
 * Component: AST field
 */
function ASTField({
    Markdown,
    parent_type,
    is_input,
    field,
    field_prefix = '',
    field_display,
    field_definition,
    ast_schema,
    no_nesting = false,
}: IASTFieldProps): ReactElement {
    const {
        value,
        isArray,
        isRequired,
        isUnique: input_is_unique,
        description: input_description,
    } = field_definition;
    const is_enum = field_definition.type === 'enum';

    //  If Object, map out subfields!
    if (typeof value === 'object' && !no_nesting) {
        const keys = astKeys(parent_type, value);

        return (
            <>
                <ASTField
                    Markdown={Markdown}
                    ast_schema={ast_schema}
                    parent_type={parent_type}
                    is_input={is_input}
                    field={field}
                    field_display={field_display}
                    field_prefix={field_prefix}
                    field_definition={field_definition}
                    no_nesting={true}
                />

                {keys.map(({ key: subfield, display_value }) => {
                    // @ts-ignore
                    const nested_definition = value[subfield] as ISchemataASTFieldDefinition;

                    let nested_field_prefix = field_prefix
                        ? `${field_prefix}.${field_display}`
                        : field_display;

                    if (isArray) {
                        nested_field_prefix += '[]';
                    }

                    return (
                        <ASTField
                            key={subfield}
                            Markdown={Markdown}
                            ast_schema={ast_schema}
                            parent_type={parent_type}
                            is_input={is_input}
                            field={subfield}
                            field_prefix={nested_field_prefix}
                            field_display={display_value}
                            field_definition={nested_definition}
                        />
                    );
                })}
            </>
        );
    }

    /**
     * Render field description
     */

    //  Field name
    const field_name = field_prefix ? `${field_prefix}.${field_display}` : field_display;

    //  Types
    const field_types = [
        typeof value === 'object'
            ? 'Object'
            : field_definition.type === 'enum'
            ? `${field_definition.enums.map(e => `"${e}"`).join(' | ')}`
            : field_definition.value,
    ];
    if (isArray) {
        field_types[0] += '[]';
    }
    if (!isRequired && !isArray && !['created_at', 'updated_at', 'id'].includes(field_display)) {
        if (is_input) {
            field_types[0] += '?';
        } else if (field_types[0] !== 'Boolean') {
            field_types.push(NULL_STRING);
        }
    }

    const formatted_types = field_types
        .map(f => {
            let raw_type = f as string;
            if (!is_enum) {
                raw_type = raw_type.toLowerCase();
            }
            const type = raw_type.replace('[]', '').replace('?', '');
            const is_array = raw_type.includes('[]');

            let formatted_type = TYPE_MAP[type] || type;
            const optionality = raw_type.includes('?') ? '?' : '';

            if (is_enum && f !== NULL_STRING) {
                formatted_type = `Enum<${formatted_type}>`;
            }

            return is_array
                ? `Array<${formatted_type}>${optionality}`
                : `${formatted_type}${optionality}`;
        })
        .join(' | ');

    //  Description
    const field_data = parent_type
        ? PropertyDescriptions[parent_type] && PropertyDescriptions[parent_type][field_name]
        : null;

    const description =
        input_description ||
        field_data?.description ||
        (PropertyDescriptions.UNIVERSAL[field_name] &&
            PropertyDescriptions.UNIVERSAL[field_name].description) ||
        null;

    //  Is field value unique in table?
    const is_unique = input_is_unique || !!field_data?.is_unique || field_display === 'id';

    // Is field value being deprecated?
    const is_deprecated = !!field_data?.is_deprecated;

    //  Classes
    let type_classes = 'entity-property--type';
    if (formatted_types.includes('?')) {
        type_classes += ' entity-property--type--optional';
    }

    return (
        <li key={field}>
            <div className='entity-property'>
                {/* Field name */}
                <div className='entity-property--name'>
                    <code>{field_name}</code>
                </div>

                {/* Type */}
                <code className={type_classes}>{formatted_types}</code>

                {/* Unique tag */}
                {is_unique ? (
                    <span className='smallcaps entity-property--unique'>Unique</span>
                ) : null}

                {/* Deprecated tag */}
                {is_deprecated ? (
                    <span className='smallcaps entity-property--deprecated'>Deprecated</span>
                ) : null}
            </div>

            {/* Description */}
            {description ? (
                <div className='entity-property--description entity-property--description--block'>
                    {Markdown.render({ src: description, nested: true })}
                </div>
            ) : null}
        </li>
    );
}

/**
 * Sort & return AST keys
 */
function astKeys(
    parent_type: string | undefined,
    value: ISchemataModuleASTTypeDefinition | ISchemataASTFieldDefinition
): Array<{
    display_value: string;
    key: string;
}> {
    return Object.keys(value)
        .map(k => ({
            display_value:
                (parent_type &&
                    REST_GQL_PROPERTY_MAPPINGS[parent_type] &&
                    REST_GQL_PROPERTY_MAPPINGS[parent_type][k]) ||
                k,
            key: k,
        }))
        .sort((a, b) =>
            a.display_value === 'id'
                ? -1
                : b.display_value === 'id'
                ? 1
                : a.display_value.localeCompare(b.display_value)
        );
}

import React, { ReactElement } from 'react';
import { IResetPasswordInvalidPageProps } from '../../types';
import { DevForgotPasswordFooter, DevLoginFooter } from './common/DevAuthFooter';
import { DevAuthHeader } from './common/DevAuthHeader';

export function ResetPasswordInvalidPage(props: IResetPasswordInvalidPageProps): ReactElement {
    return (
        <div className='narrow'>
            <DevAuthHeader />

            <div className='block submitted_loading'>
                <div className='card login_card submitted_hide'>
                    <div>
                        <p className='invalid'>
                            Unfortunately, this link is no longer valid. You are welcome to try to
                            reset your password again.
                        </p>
                    </div>
                </div>
            </div>

            <DevLoginFooter />

            <DevForgotPasswordFooter />
        </div>
    );
}

/**
 * Custom renderer: <type />
 * - For field types
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IHTMLToReactNode, ICustomRenderer } from '../types';

// <type value="$VALUE" />
export function typeString(): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement | null => {
            const { value, optional } = node.attribs;

            if (!value) {
                return null;
            }

            let classes = 'entity-property--type';
            if (typeof optional !== 'undefined') {
                classes += ' entity-property--type--optional';
            }

            const title =
                typeof optional !== 'undefined' ? 'Optional property' : 'Required property';

            return (
                <code title={title} className={classes}>
                    {value.toLowerCase()}
                </code>
            );
        },
    };
}

/**
 * Portal body
 */
import React, { ChangeEvent, MouseEvent, ReactElement, useState } from 'react';
import { postRequest } from '../../../../../client-js/common/domutil';
import { VALID_EMAIL_PATTERN } from '../../../../../common/globals';
import { validateURL } from '../../../../../common/validation/validateURL';
import { LOGOUT_PATH, PORTAL_INVITE_PATH, PORTAL_UPDATE_PATH } from '../../common/globals';
import {
    IDeveloperPortalInviteRequest,
    IDeveloperPortalInviteResponse,
    IDeveloperPortalUpdateRequest,
    IDeveloperPortalUpdateResponse,
    IPortalPageProps,
} from '../../types';

/**
 * Exports
 */
export function PortalBody(props: IPortalPageProps): ReactElement {
    return (
        <div id='body' className='body--portal'>
            <h1>Your API Credentials</h1>

            <PortalCredentials {...props} />

            <div className='break'></div>
            <PortalTeamMembers {...props} />

            <div className='break'></div>
            <div className='body--section credentials--form'>
                <p>
                    <a href={LOGOUT_PATH}>Sign out</a>
                </p>
            </div>
        </div>
    );
}

/**
 * Component: Credentials form
 */
function PortalCredentials(props: IPortalPageProps): ReactElement {
    const { credentials } = props;

    /**
     * State
     */
    const [privacy_policy_uri, setPrivacyPolicyUri] = useState(credentials.privacy_policy);
    const [terms_of_use_uri, setTermsOfUseUri] = useState(credentials.terms_of_use);
    const [active_redirect_uri, setActiveRedirectUri] = useState('');
    const [redirect_uris, setRedirectUris] = useState(credentials.approved_redirect_uris);
    const [is_loading, setIsLoading] = useState(false);
    const [is_success, setIsSuccess] = useState(false);

    //  Errors
    const [privacy_policy_uri_error, setPrivacyPolicyUriError] = useState<string | null>(null);
    const [terms_of_use_uri_error, setTermsOfUseUriError] = useState<string | null>(null);
    const [active_redirect_uri_error, setActiveRedirectUriError] = useState<string | null>(null);
    const [redirect_uri_errors, setRedirectUriErrors] = useState<Array<string>>([]);
    const [request_error, setRequestError] = useState<string | null>(null);

    /**
     * Methods
     */
    function onPrivacyPolicyChange(e: ChangeEvent<HTMLInputElement>): void {
        const { value } = e.target;
        setPrivacyPolicyUri(value);
        setIsSuccess(false);
        setRequestError(null);
        setPrivacyPolicyUriError(null);
    }

    function onTermsChange(e: ChangeEvent<HTMLInputElement>): void {
        const { value } = e.target;
        setTermsOfUseUri(value);
        setIsSuccess(false);
        setRequestError(null);
        setTermsOfUseUriError(null);
    }

    function onActiveRedirectUriChange(e: ChangeEvent<HTMLInputElement>): void {
        const { value } = e.target;
        setActiveRedirectUri(value);
        setIsSuccess(false);
        setRequestError(null);
        setActiveRedirectUriError(null);
        setRedirectUriErrors([]);
    }

    function addActiveRedirectUri(e: MouseEvent): void {
        if (!active_redirect_uri || !validateURL(active_redirect_uri).is_valid) {
            return setActiveRedirectUriError(
                `Invalid URI – please ensure that the URI is properly formatted, e.g. "https://example.com"`
            );
        }

        //  Reset error state
        setActiveRedirectUriError(null);

        //  Append
        const uris = [...redirect_uris, active_redirect_uri];
        setRedirectUris(uris);
        setActiveRedirectUri('');
    }

    function removeRedirectUri(n: number): (e: MouseEvent) => void {
        return (e: MouseEvent): void => {
            const uris = [...redirect_uris];
            uris.splice(n, 1);
            setRedirectUris(uris);
        };
    }

    function saveCredentialData(e: MouseEvent): void {
        e.preventDefault();

        if (is_loading) {
            return;
        }

        // Validate fields: Redirect URIs
        const uri_errors = [];
        for (const uri of redirect_uris) {
            if (!validateURL(uri).is_valid) {
                uri_errors.push(`Invalid URI: "${uri}"`);
            }
        }
        setRedirectUriErrors(uri_errors);

        // Validate fields: Privacy policy
        const policy_error = validateURL(privacy_policy_uri).is_valid
            ? null
            : 'Invalid privacy policy URL';
        setPrivacyPolicyUriError(policy_error);

        // Validate fields: Terms of use
        const terms_error = validateURL(terms_of_use_uri).is_valid
            ? null
            : 'Invalid terms of use URL';
        setTermsOfUseUriError(terms_error);

        if (uri_errors.length || policy_error || terms_error) {
            return;
        }

        /**
         * POST request
         */
        //  Set loading state, reset error states
        setIsLoading(true);
        setIsSuccess(false);
        setRequestError(null);
        setPrivacyPolicyUriError(null);
        setTermsOfUseUriError(null);
        setRedirectUriErrors([]);

        postRequest<IDeveloperPortalUpdateRequest, IDeveloperPortalUpdateResponse>({
            url: PORTAL_UPDATE_PATH,
            body: {
                privacy_policy_uri,
                terms_of_use_uri,
                redirect_uris,
            },
        })
            .then(res => {
                setIsLoading(false);

                const {
                    server_error,
                    privacy_policy_uri_error: server_privacy_policy_uri_error,
                    terms_of_use_uri_error: server_terms_of_use_uri_error,
                    redirect_uri_errors: server_redirect_uri_errors,
                } = res;

                //  Error?
                const is_error =
                    server_error ||
                    server_privacy_policy_uri_error ||
                    server_terms_of_use_uri_error ||
                    server_redirect_uri_errors?.length;

                setRequestError(server_error || null);
                setPrivacyPolicyUriError(server_privacy_policy_uri_error || null);
                setTermsOfUseUriError(server_terms_of_use_uri_error || null);
                setRedirectUriErrors(server_redirect_uri_errors || []);

                //  Success?
                if (!is_error) {
                    setIsSuccess(true);
                }
            })
            .catch(error => {
                setIsLoading(false);
                setIsSuccess(false);
                setRequestError(`Server error – please try again`);
            });
    }

    /**
     * Render
     */
    return (
        <>
            <div className='body--section credentials--form'>
                <div className='highlight highlight--blue portal--credentials'>
                    <div>
                        <span className='highlight--label highlight--label--creds'>
                            <strong>Company name</strong>:
                        </span>
                        <span className='highlight--value highlight--value--creds'>
                            {credentials.company_name}
                        </span>
                    </div>

                    <div>
                        <span className='highlight--label highlight--label--creds'>
                            <strong>Client ID</strong>:
                        </span>
                        <code className='highlight--value highlight--value--creds'>
                            {credentials.client_id}
                        </code>
                    </div>

                    <div>
                        <span className='highlight--label highlight--label--creds'>
                            <strong>Client secret</strong>:
                        </span>
                        <code className='highlight--value highlight--value--creds'>
                            {credentials.client_secret}
                        </code>
                    </div>
                </div>

                {/* Scopes */}
                <h4>Approved scopes</h4>
                <p>
                    {credentials.approved_scopes.map(scope => (
                        <span key={scope} className={`scope`}>
                            {scope}
                        </span>
                    ))}
                </p>

                {/* Redirect URIs */}
                <h4>Redirect URIs</h4>
                <ul className='redirect-uri--list'>
                    {/* List current URIs */}
                    {redirect_uris.map((uri, n) => (
                        <li key={uri} className='redirect-uri--item'>
                            <code className='redirect-uri--uri'>{uri}</code>

                            {/* Delete button */}
                            <a
                                href='javascript:void(0)'
                                className='redirect-uri--button redirect-uri--delete'
                                onClick={removeRedirectUri(n)}
                            >
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                                    <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
                                </svg>
                            </a>
                        </li>
                    ))}

                    {/* Add new URI */}
                    <li className='redirect-uri--item redirect-uri--item--new'>
                        <input
                            className='redirect-uri--uri'
                            type='text'
                            placeholder='Add redirect URI...'
                            value={active_redirect_uri}
                            onChange={onActiveRedirectUriChange}
                            disabled={is_loading}
                        />
                        <a
                            href='javascript:void(0)'
                            className='redirect-uri--button'
                            onClick={addActiveRedirectUri}
                        >
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                                <path d='M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z' />
                            </svg>
                        </a>
                    </li>
                </ul>

                {/* Active URI error */}
                {active_redirect_uri_error ? (
                    <p className='highlight highlight--red highlight--error'>
                        <span className='error--name'>{active_redirect_uri_error}</span>
                    </p>
                ) : null}

                {/* Submitted URI error(s) */}
                {redirect_uri_errors.length
                    ? redirect_uri_errors.map(error => (
                          <p key={error} className='highlight highlight--red highlight--error'>
                              <span className='error--name'>{error}</span>
                          </p>
                      ))
                    : null}

                {/* Privacy policy */}
                <h4>Privacy policy</h4>
                <p>
                    <input
                        type='text'
                        placeholder='Privacy policy URL...'
                        name='privacy_policy'
                        value={privacy_policy_uri}
                        onChange={onPrivacyPolicyChange}
                        disabled={is_loading}
                    />
                </p>

                {privacy_policy_uri_error ? (
                    <p className='highlight highlight--red highlight--error'>
                        <span className='error--name'>{privacy_policy_uri_error}</span>
                    </p>
                ) : null}

                {/* Terms */}
                <h4>Terms of use</h4>
                <p>
                    <input
                        type='text'
                        placeholder='Terms of use URL...'
                        name='terms_of_use'
                        value={terms_of_use_uri}
                        onChange={onTermsChange}
                        disabled={is_loading}
                    />
                </p>

                {terms_of_use_uri_error ? (
                    <p className='highlight highlight--red highlight--error'>
                        <span className='error--name'>{terms_of_use_uri_error}</span>
                    </p>
                ) : null}

                {/* Request error */}
                {request_error ? (
                    <p className='highlight highlight--red highlight--error'>
                        <span className='error--name'>{request_error}</span>
                    </p>
                ) : null}

                {/* Success */}
                {is_success ? (
                    <p className='highlight highlight--green highlight--success'>
                        <span>Update successful.</span>
                    </p>
                ) : null}

                {/* Submit */}
                <p className={`creds--submit`}>
                    <button
                        onClick={saveCredentialData}
                        className={`contained_button submit_input ${
                            is_loading ? `background_loading_gif--button` : ''
                        }`}
                    >
                        Save changes
                    </button>
                </p>
            </div>
        </>
    );
}

/**
 * Component: Team members
 */
function PortalTeamMembers(props: IPortalPageProps): ReactElement {
    const { team } = props;

    /**
     * State
     */
    const [invite_email_address, setInviteEmailAddress] = useState('');
    const [invite_email_address_delivered, setInviteEmailAddressDelivered] = useState('');
    const [invite_email_error, setInviteEmailError] = useState<string | null>(null);
    const [request_error, setRequestError] = useState<string | null>(null);
    const [is_loading, setIsLoading] = useState(false);
    const [is_success, setIsSuccess] = useState(false);

    /**
     * Methods
     */
    function onInviteEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const { value } = e.target;
        setInviteEmailAddress(value);
        setIsSuccess(false);
        setInviteEmailError(null);
        setRequestError(null);
    }

    function sendDeveloperInvite(e: MouseEvent): void {
        e.preventDefault();

        if (is_loading) {
            return;
        }

        // Validate fields: Email
        const error = VALID_EMAIL_PATTERN.test(invite_email_address)
            ? null
            : 'Invalid email address';

        setInviteEmailError(error);
        setRequestError(null);

        if (error) {
            return;
        }

        /**
         * POST request
         */
        //  Set loading state, reset error states
        setIsLoading(true);
        setIsSuccess(false);
        setInviteEmailError(null);
        setRequestError(null);

        postRequest<IDeveloperPortalInviteRequest, IDeveloperPortalInviteResponse>({
            url: PORTAL_INVITE_PATH,
            body: {
                email: invite_email_address,
            },
        })
            .then(res => {
                setIsLoading(false);

                const { server_error, email_error } = res;

                //  Error?
                const is_error = server_error || email_error;

                setInviteEmailError(email_error || null);

                //  Success?
                if (!is_error) {
                    setIsSuccess(true);
                    setInviteEmailAddressDelivered(invite_email_address);

                    //  Reset
                    setInviteEmailAddress('');
                }
            })
            .catch(err => {
                setIsLoading(false);
                setIsSuccess(false);
                setRequestError(`Server error – please try again`);
            });
    }

    return (
        <>
            <h2>Team members</h2>
            <div className='body--section credentials--form'>
                <ul className='redirect-uri--list'>
                    {team.map(member => (
                        <li
                            key={member.email}
                            className='redirect-uri--item redirect-uri--item-member'
                        >
                            <span className='li--bullet'>&bull;</span>
                            <span className='redirect-uri--uri redirect-uri--member'>
                                {member.email}
                            </span>
                        </li>
                    ))}
                </ul>

                <h4>Invite a team member</h4>
                <ul className='redirect-uri--list'>
                    <li className='redirect-uri--item redirect-uri--item--new'>
                        <input
                            type='email'
                            name='invite_email'
                            placeholder="Team member's email address..."
                            value={invite_email_address}
                            onChange={onInviteEmailChange}
                            disabled={is_loading}
                        />
                    </li>
                </ul>

                {/* Error */}
                {invite_email_error || request_error ? (
                    <p className='highlight highlight--red highlight--error'>
                        <span className='error--name'>{invite_email_error || request_error}</span>
                    </p>
                ) : null}

                {/* Success */}
                {is_success ? (
                    <p className='highlight highlight--green highlight--success'>
                        <span>Invitation sent to {invite_email_address_delivered}</span>
                    </p>
                ) : null}

                {/* Submit */}
                <p className='creds--submit'>
                    <button
                        className={`contained_button submit_input ${
                            is_loading ? `background_loading_gif--button` : ''
                        }`}
                        onClick={sendDeveloperInvite}
                    >
                        Send invitation
                    </button>
                </p>
            </div>
        </>
    );
}

/**
 * Custom renderer: <method />
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IHTMLToReactNode, ICustomRenderer } from '../types';

// <method type="$TYPE" />
export function method(): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement | null => {
            const { type } = node.attribs;

            if (!type) {
                return null;
            }

            return (
                <span className={`method method--${type.toLowerCase()}`}>{type.toUpperCase()}</span>
            );
        },
    };
}

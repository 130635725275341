/**
 * Documentation sidebar
 * - Desktop only
 */
import React, { ReactElement } from 'react';
import { IDocumentationSidebarProps } from '../../../types';
import { SidebarBody } from './SidebarBody';
import { SidebarFooter } from './SidebarFooter';
import { SidebarHeader } from './SidebarHeader';

export function DocumentationSidebar(props: IDocumentationSidebarProps): ReactElement {
    return (
        <div id='sidebar'>
            <SidebarHeader {...props} />
            <SidebarBody {...props} />
            <SidebarFooter {...props} />
        </div>
    );
}

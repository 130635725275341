/**
 * Custom renderer: <scopeblock />
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IHTMLToReactNode, ICustomRenderer } from '../types';

// <scopeblock scopes="$VALUE" />
export function scopeblock(): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement => {
            const { scopes: input_scopes = '' } = node.attribs;
            const scopes = input_scopes.split(',').map(s => s.trim());

            return (
                <p>
                    {scopes.map(scope => (
                        <a key={scope} className='scope' href='#scopes'>
                            {scope}
                        </a>
                    ))}
                </p>
            );
        },
    };
}

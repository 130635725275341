/**
 * Default renderer: <pre>
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IDefaultRenderer } from '../types';

interface CodeProps {
    language: string;
    value: string;
}

/**
 * - Pretty-print code blocks
 */
export function pre(): IDefaultRenderer {
    return (pre_props: CodeProps): ReactElement | null => {
        const { language, value } = pre_props;

        return <pre className={`prettyprint lang-${language}`}>{value}</pre>;
    };
}

/**
 * Documentation toolbar
 * - Mobile only
 */
import React, { ReactElement } from 'react';
import { IDocumentationSidebarProps } from '../../../types';

export function DocumentationToolbar(props: IDocumentationSidebarProps): ReactElement {
    return <></>;
}

/**
 * Custom renderer: <highlight />
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IHTMLToReactNode, ICustomRenderer, ChildRenderFunction } from '../types';

// <highlight colour="$COLOUR">$CONTENT</highlight>
export function highlight(props: { renderChildren: ChildRenderFunction }): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement => {
            const { colour: input_colour, color } = node.attribs;
            const colour = input_colour || color || 'blue';

            return (
                <div className={`highlight highlight--${colour}`}>
                    {props.renderChildren({ components: children })}
                    {/* <ReactMarkdownChildren components={children} /> */}
                </div>
            );
        },
    };
}

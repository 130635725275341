/**
 * React entry point
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {
    DocumentationPage,
    ForgotPasswordPage,
    ForgotPasswordSentPage,
    InvalidInvitationPage,
    JoinPage,
    LoginPage,
    PortalPage,
    ResetPasswordDonePage,
    ResetPasswordInvalidPage,
    ResetPasswordPage,
} from './pages';
import { IDeveloperRoutePages } from './types';

//  NB: Using `declare global { interface Window {` in multiple places in the codebase (i.e. in each src/index.tsx file) causes issues
//  - Need to use (window as Window)
interface Window {
    __APP_PROPS__?: IDeveloperRoutePages;
}

/**
 * 1: Get <App> props
 */
const root = document.getElementById('root');
const app_props = (window as Window).__APP_PROPS__;

/**
 * 2: Hydrate
 */
if (app_props?.page === 'documentation') {
    require('./scss/documentation-portal.scss');
    ReactDOM.hydrate(<DocumentationPage {...app_props} />, root);
} else if (app_props?.page === 'portal') {
    require('./scss/documentation-portal.scss');
    ReactDOM.hydrate(<PortalPage {...app_props} />, root);
} else if (app_props?.page === 'login') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<LoginPage {...app_props} />, root);
} else if (app_props?.page === 'join') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<JoinPage {...app_props} />, root);
} else if (app_props?.page === 'invalid-invitation') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<InvalidInvitationPage {...app_props} />, root);
} else if (app_props?.page === 'forgot-password') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<ForgotPasswordPage {...app_props} />, root);
} else if (app_props?.page === 'forgot-password-sent') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<ForgotPasswordSentPage {...app_props} />, root);
} else if (app_props?.page === 'reset-password') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<ResetPasswordPage {...app_props} />, root);
} else if (app_props?.page === 'reset-password-done') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<ResetPasswordDonePage {...app_props} />, root);
} else if (app_props?.page === 'reset-password-invalid') {
    require('./scss/login.dev.scss');
    ReactDOM.hydrate(<ResetPasswordInvalidPage {...app_props} />, root);
}
//  Validation: `n` must be `undefined`!
else {
    const n: undefined = app_props;
}

// Clear out APP_PROPS after hydration
delete (window as Window).__APP_PROPS__;

/**
 * Default renderer: <p>
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IDefaultRenderer, ChildRenderFunction } from '../types';

/**
 * - Only render <p> if child is a text node
 */
export function paragraph(props: { renderChildren: ChildRenderFunction }): IDefaultRenderer {
    return (p_props: any): ReactElement => {
        const p_type = (p_props.children[0] && p_props.children[0].type?.name) || 'TextRenderer';

        return p_type === 'TextRenderer' || p_type === 'text' ? (
            <p>{props.renderChildren({ components: p_props.children })}</p>
        ) : (
            props.renderChildren({ components: p_props.children })
        );
    };
}

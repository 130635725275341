/**
 * Custom renderer: <loom />
 */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { IHTMLToReactNode, ICustomRenderer } from '../types';

// <loom url="$URL" />
export function loom(): ICustomRenderer {
    return {
        processNode: (
            node: IHTMLToReactNode,
            children: Array<ReactElement | string>
        ): ReactElement | null => {
            const { url: loom_share_url } = node.attribs;

            if (!loom_share_url) {
                return null;
            }

            // https://www.loom.com/share/8740281f54e8450c9ef1328a1d06a87c
            const src = loom_share_url.replace('/share/', '/embed/');

            return (
                // div style style='position: relative; padding-bottom: 92.3076923076923%; height: 0;
                // style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;'
                <div className='loom'>
                    <iframe
                        src={src}
                        frameBorder='0'
                        allowFullScreen={true}
                        title={`Loom: ${src}`}
                    ></iframe>
                </div>
            );
        },
    };
}

/**
 * Component: Sidebar footer
 */
import React, { ReactElement } from 'react';
import { IDocumentationSidebarProps } from '../../../types';
import { PORTAL_PATH } from '../../../common/globals';

/**
 * Footer
 * - A: If logged in, show link to portal
 * - B: Else, show sign-in link
 */
export function SidebarFooter(props: IDocumentationSidebarProps): ReactElement {
    const { user, credentials, login_path, originalUrl } = props;

    return (
        <div className='sidebar--footer'>
            {user && credentials && originalUrl.startsWith(PORTAL_PATH) ? (
                <div className='sidebar--footer--user'>
                    <div>
                        <p className='sidebar--footer--user--company'>{credentials.company_name}</p>
                        <p className='sidebar--footer--user--email'>{user.email}</p>
                    </div>
                </div>
            ) : user && credentials ? (
                <a
                    className='sidebar--footer--user'
                    href={PORTAL_PATH}
                    title={'Access developer portal'}
                >
                    <div>
                        <p className='sidebar--footer--user--company'>{credentials.company_name}</p>
                        <p className='sidebar--footer--user--email'>{user.email}</p>
                    </div>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                        <path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' />
                    </svg>
                </a>
            ) : (
                <a className='sidebar--footer--login-link' href={login_path}>
                    <div>
                        <p>Sign in to Developer Portal</p>
                    </div>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                        <path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' />
                    </svg>
                </a>
            )}
        </div>
    );
}

/**
 * TEMPORARY
 * - Until Schema v2 is ready
 */

const properties: {
    [type: string]: {
        [field: string]: {
            description: string;
            is_unique?: boolean;
            is_deprecated?: boolean;
        };
    };
} = {
    UNIVERSAL: {
        id: {
            description: `Entity ID`,
        },
        created_at: {
            description: `Entity creation date.`,
        },
        cursor: {
            description: `A pagination cursor.`,
        },
        updated_at: {
            description: `Entity last-updated date.`,
        },
    },

    //  Booking == Job
    Job: {
        id: {
            description: `The Booking entity ID.`,
        },
        amount: {
            description: `The payment amount associated with the Booking, in dollars.`,
        },
        person_id: {
            description: `The client's [Person](#person) entity ID.`,
            is_deprecated: true,
        },
        person_ids: {
            description: `The clients' [Person](#person) entity IDs.`,
        },
        person: {
            description: `A Person entity to create.`,
        },
        scheduled_time: {
            description: `The date the Booking is/was scheduled for.`,
        },
        completed_time: {
            description: `The date the Booking was completed. The value should be a Date when <code>complete: true</code>`,
        },
        complete: {
            description: `Whether the work associated with the Booking has been completed.`,
        },
        employee_ids: {
            description: `[Employees](#employee) who worked on the Booking.`,
        },
        employees: {
            description: `[Employee](#employee) entities to create.`,
        },
        name: {
            description: `A name for the Booking.`,
        },
        photo_ids: {
            description: `Photo entities associated with the Booking.`,
        },
        tags: {
            description: `Custom tags associated with the Booking. The <code>noreview</code> tag will prevent a Booking from triggering a CampaignEnrollment on completion.`,
        },
    },

    Case: {
        id: {
            description: `The Case entity ID.`,
        },
        person_id: {
            description: `The client's [Person](#person) entity ID.`,
        },
        employee_ids: {
            description: `[Employees](#employee) associated with the Case.`,
        },
        name: {
            description: `A name for the Case.`,
        },
        description: {
            description: 'A description of the Case.',
        },
        completed_time: {
            description: `The date the Case was completed.`,
        },
        tags: {
            description: `Custom tags associated with the Case.`,
        },
    },

    Campaign: {
        id: {
            description: `The Campaign entity ID.`,
        },
        objective: {
            description: `The Campaign objective.`,
        },
        name: {
            description: `The campaign name.`,
        },
        description: {
            description: `A description of the campaign.`,
        },
    },

    CampaignEnrollment: {
        id: {
            description: `The CampaignEnrollment entity ID.`,
        },
        campaign_id: {
            description: `The enrollment's parent Campaign entity ID.`,
        },
        person_id: {
            description: `The subject [Person](#person) entity ID.`,
        },
    },

    Company: {
        company_name: {
            description: `The name of the company.`,
        },
        email: {
            description: `The Company's validated email address.`,
        },
        phone: {
            description: `The Company's validated phone number.`,
        },
        address: {
            description: `The Company's validated address object.`,
        },
        website: {
            description: `The Company's homepage.`,
        },
        industry: {
            description: `The Company's industry.`,
        },
        slug: {
            description: `A URL-safe company name slug.`,
        },
    },

    Employee: {
        id: {
            description: `The Employee entity ID.`,
        },
        email: {
            description: `The Employee's validated email address.`,
            is_unique: true,
        },
        phone: {
            description: `The Employee's validated phone number.`,
        },
        position: {
            description: `The Employee's position at the [Company](#company).`,
        },
    },

    Invoice: {
        id: {
            description: `The Invoice entity ID.`,
        },
        person_ids: {
            description: `The clients' [Person](#person) entity IDs.`,
        },
        employee_ids: {
            description: `[Employees](#employee) associated with the Invoice.`,
        },
        subtotal: {
            description: 'The subtotal of the Invoice before discounts and taxes are applied.',
        },
        total: {
            description: 'The total of the Invoice after discounts and taxes are applied.',
        },
        currency: {
            description: 'The currency of the Invoice (Expects an ISO-4217 Currency Code).',
        },
        balance: {
            description: 'The outstanding balance of the Invoice.',
        },
        discounts: {
            description: 'Any discounts applied to the Invoice.',
        },
        'discounts[].name': {
            description: 'The name of the discount.',
        },
        'discounts[].description': {
            description: 'A description of the discount.',
        },
        'discounts[].amount': {
            description: 'The amount of the discount.',
        },
        'discounts[].percentage': {
            description: 'The rate at which the Invoice is discounted.',
        },
        taxes: {
            description: 'Any taxes applied to the Invoice.',
        },
        'taxes[].name': {
            description: 'The name of the tax.',
        },
        'taxes[].description': {
            description: 'A description of the tax.',
        },
        'taxes[].amount': {
            description: 'The amount of the tax.',
        },
        'taxes[].percentage': {
            description: 'The rate at which the Invoice is taxed.',
        },
        line: {
            description: 'Any service or item added to the Invoice.',
        },
        'line[].amount': {
            description: 'The amount of the line item on the Invoice.',
        },
        'line[].description': {
            description: 'A description of the line item.',
        },
        paid: {
            description: 'Whether or not the Invoice has been paid.',
        },
        paid_at: {
            description: 'When the Invoice was paid.',
        },
        description: {
            description: 'A description of the Invoice.',
        },
        tags: {
            description: `Custom tags associated with the Invoice.`,
        },
    },

    Payment: {
        id: {
            description: `The Payment entity ID.`,
        },
        person_ids: {
            description: `The clients' [Person](#person) entity IDs.`,
        },
        invoice_ids: {
            description: `[Invoices](#invoices) associated with the Payment.`,
        },
        amount: {
            description: 'The amount of of the Payment.',
        },
        amount_refunded: {
            description: 'The amount refunded if any.',
        },
        currency: {
            description:
                'The currency used to make the Payment (Expects an ISO-4217 Currency Code).',
        },
        description: {
            description: 'A description of the Payment.',
        },
    },

    Person: {
        id: {
            description: `The Person entity ID.`,
        },
        email: {
            is_unique: true,
            description: `The Person's validated email address.`,
        },
        phone: {
            description: `The Person's validated phone number.`,
        },
        company: {
            description: `The company at which the Person works.`,
        },
        position: {
            description: `The person's position at their company.`,
        },
        blocked: {
            description: `Whether the Person has been blocked from receiving campaign requests.`,
        },
        tags: {
            description: `Custom tags associated with the Person.`,
        },
        initial_source: {
            description: `The initial source of the Person's data.`,
        },
    },

    ReviewResponseExternal: {
        id: {
            description: `The Review entity ID.`,
        },
        person_id: {
            description: `The related [Person](#person) ID.`,
        },
        network: {
            description: `The source network.`,
        },
        author: {
            description: `The author name on the [Review](#review) network.`,
        },
        rating: {
            description: `The [Review](#review) rating.`,
        },
        created_at: {
            description: `The Review creation date.`,
        },
        microsite_url: {
            description: "URL to the review on the Company's NiceJob Microsite.",
        },
        source_url: {
            description:
                'Deeplink URL to the original review at the external source.  Some review sources do not support deep-linking to individual reviews and so in this case, the source_url will be null.',
        },
        source_base_url: {
            description:
                'Link to the original review source.  In order to support review-linking functionality when source_url is not provided',
        },
    },

    ReviewInsightsResponse: {
        average_rating: {
            description: `The average rating of all of a [Company](#company)'s reviews.`,
        },
        review_count: {
            description: `The total number of [Company](#company) reviews.`,
        },
        'network_insights[].network': {
            description: `[Review](#review) network name.`,
        },
    },

    Visit: {
        id: {
            description: `The Visit entity ID.`,
        },
        amount: {
            description: `The payment amount associated with the Visit, in dollars.`,
        },
        booking_id: {
            description: `The Visit entity's parent [Booking](#booking) ID.`,
        },
        person_id: {
            description: `The client's [Person](#person) entity ID.`,
        },
        person: {
            description: `A Person entity to create.`,
        },
        scheduled_time: {
            description: `The date the Visit is/was scheduled for.`,
        },
        completed_time: {
            description: `The date the Visit was completed.`,
        },
        complete: {
            description: `Whether the work associated with the Visit has been completed.`,
        },
        employee_ids: {
            description: `[Employees](#employee) who worked the Visit.`,
        },
        employees: {
            description: `[Employee](#employee) entities to create.`,
        },
        name: {
            description: `A name for the Visit.`,
        },
        photo_ids: {
            description: `Photo entities associated with the Visit.`,
        },
        tags: {
            description: `Custom tags associated with the Visit.`,
        },
    },

    Webhook: {
        id: {
            description: `Webhook ID`,
        },
        client_id: {
            description: `Developer account Client ID`,
        },
        endpoint_url: {
            description: `Endpoint to which relevant webhook messages will be published`,
        },
        subscriptions: {
            description: `List of entities associated with this webhook`,
        },
        secret: {
            description: `Generated on webhook creation and returned only in the Create Webhook response; used to verify webhook messages`,
        },
        is_enabled: {
            description: `Is the webhook enabled?`,
        },
    },

    //  Queries
    employees: {
        email: {
            description: `Filter results by Employee email.`,
        },
    },

    jobs: {
        person_id: {
            description: `Filter results by [Person](#person).`,
        },
    },

    peopleBasic: {
        person_id: {
            description: `Filter results by [Person](#person).`,
        },
        email: {
            description: `Filter results by Person email.`,
        },
    },

    reviewInsights: {
        person_id: {
            description: `Filter results by [Person](#person).`,
        },
    },

    visits: {
        person_id: {
            description: `Filter results by [Person](#person).`,
        },
    },
};

/**  Map inputs to types  */
const INPUT_MAP: {
    [input: string]: Array<string>;
} = {
    CampaignEnrollment: ['RequestCampaignEnrollmentRESTInput'],
    Employee: ['CreateEmployeeInput', 'UpdateEmployeeInput'],
    Job: ['CreateJobInput', 'UpdateJobInput'],
    Person: ['CreatePersonInput', 'UpdatePersonInput'],
    Visit: ['CreateVisitInput', 'UpdateVisitInput'],
    Webhook: ['CreateWebhookInput', 'UpdateWebhookInput', 'DeleteWebhookInput', 'TestWebhookInput'],
};

for (const k in INPUT_MAP) {
    for (const input of INPUT_MAP[k]) {
        properties[input] = properties[k];
    }
}

/**  Export  */
export const PropertyDescriptions = properties;

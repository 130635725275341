import React, { ChangeEvent, ReactElement, useState } from 'react';
import { validatePassword as commonValidatePassword } from '../../../../../common/validation/validatePassword';
import { IJoinPageProps } from '../../types';
import { DevLoginFooter } from './common/DevAuthFooter';
import { DevAuthHeader } from './common/DevAuthHeader';

export function JoinPage(props: IJoinPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */

    return (
        <div className='narrow'>
            <DevAuthHeader />

            <JoinPageBody {...props} />
        </div>
    );
}

function JoinPageBody(props: IJoinPageProps): ReactElement {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [is_loading, setIsLoading] = useState(false);
    const [validation_error, setValidationError] = useState<string>();

    function onChange(e: ChangeEvent<HTMLInputElement>): void {
        const { name, value } = e.target;

        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    }

    function validatePassword(): void {
        const { valid, message } = commonValidatePassword({ password, email });

        if (!valid) {
            setValidationError(message);
        } else {
            setValidationError(undefined);
        }
    }

    /**
     * Set loading state when submitting the form
     */
    function onRegister(): void {
        setIsLoading(true);
    }

    return (
        <>
            <div className={`block submitted_loading ${is_loading ? 'form_submitted' : ''}`}>
                <div className='card login_card submitted_hide'>
                    <form
                        onSubmit={onRegister}
                        action={`/docs/join/${props.invitation_id}`}
                        method='POST'
                        className='submit_exit submitted_disabled'
                    >
                        <p className='join'>Welcome to the NiceJob API!</p>

                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='Email address'
                                type='email'
                                name='email'
                                value={email}
                                onChange={onChange}
                            />
                        </div>
                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='Password'
                                type='password'
                                name='password'
                                value={password}
                                onChange={onChange}
                                onBlur={validatePassword}
                            />
                        </div>
                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='Confirm password'
                                type='password'
                                name='verify-password'
                            />
                        </div>
                        <div className='row submit_row'>
                            <input
                                className='contained_button submit_input'
                                type='submit'
                                value='Register for the NiceJob API'
                                disabled={is_loading}
                            />
                        </div>
                    </form>

                    {validation_error && (
                        <div className='error'>
                            <span>{validation_error}</span>
                        </div>
                    )}

                    {props.error_message && (
                        <div className='error'>
                            <strong>Signup error:</strong>
                            <span>{props.error_message}</span>
                        </div>
                    )}
                </div>
            </div>

            <DevLoginFooter />
        </>
    );
}

import React, { ReactElement } from 'react';
import { IResetPasswordDonePageProps } from '../../types';
import { DevLoginFooter } from './common/DevAuthFooter';
import { DevAuthHeader } from './common/DevAuthHeader';

export function ResetPasswordDonePage(props: IResetPasswordDonePageProps): ReactElement {
    return (
        <div className='narrow'>
            <DevAuthHeader />

            <div className='block submitted_loading'>
                <div className='card login_card submitted_hide'>
                    <div>
                        <p className='invalid'>
                            Success! Your password has been reset. Click on the login link below to
                            log back in to the NiceJob API.
                        </p>
                    </div>
                </div>
            </div>

            <DevLoginFooter />
        </div>
    );
}

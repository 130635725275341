/**
 * Common methods
 */
import React, { MouseEvent } from 'react';
import { parseUrl } from '../../common/parseUrl';
import { IDocumentationSidebarProps } from '../../types';
import { getById, getParent } from '../../../../../client-js/common/domutil';
import { DOCUMENTATION_BODY_CLASS } from '../../common/globals';

/**
 * Generate an element ID for a given HTML header element
 * @param header_text - Header text
 */
export function headerId(header_text: string): string {
    return header_text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]/g, '');
}

/**
 * Navigation
 */
export function NavigateToDocumentationPage({
    hostname,
    url_prefix: input_url_prefix,
    url: current_url,
    setUrl,
    setLoginPath,
    setOriginalUrl,
}: Record<'hostname' | 'url_prefix' | 'url', string> &
    Record<
        'setUrl' | 'setLoginPath' | 'setOriginalUrl',
        React.Dispatch<React.SetStateAction<string>>
    >): IDocumentationSidebarProps['navigateToDocumentationPage'] {
    /**
     * URL regex parameters
     * - For extracting host & URL path (post-host), stripped of query/anchor
     */
    const url_prefix = !input_url_prefix ? '/' : input_url_prefix;
    const post_prefix_pattern = '($|\\/.*?)(#|\\?|$)';
    const url_path_pattern =
        url_prefix === '/'
            ? post_prefix_pattern
            : `${url_prefix.replace(/\//g, '\\/')}${post_prefix_pattern}`;

    // [1[: Hostname with protocol
    // [3]: URL path, stripped of query / anchor
    const documentation_href_pattern = new RegExp(
        `(^https?:\\/\\/${hostname.replace(/\./g, '\\.')})(:\\d+)*${url_path_pattern}`
    );

    /**
     * Set up PopState listener
     * - Triggered on usage of back/forward browser buttons
     */
    if (typeof window !== 'undefined') {
        window.onpopstate = (): void => {
            const destination_url = document.location.toString();

            // Is destination within the documentation?
            const match = documentation_href_pattern.exec(destination_url);

            // A: No? Default navigation
            if (!match) {
                return;
            }

            // B: Otherwise, only navigate if there's state if destination is different page
            const destination_url_path = url_prefix + match[3];
            const current_url_path = input_url_prefix + current_url;

            if (destination_url_path !== current_url_path) {
                return navigateToPage({
                    url_path: destination_url_path,
                    url_prefix,
                    setUrl,
                    setLoginPath,
                    setOriginalUrl,
                });
            }
        };
    }

    /**
     * Return method
     */
    return (e: MouseEvent<HTMLAnchorElement>): void => {
        const target = (e.currentTarget || e.target) as HTMLAnchorElement;
        const { href } = target;

        // Is href within the documentation?
        const match = documentation_href_pattern.exec(href);
        const body_div = getById('body');

        // A: No? Use default navigation
        if (!match || !body_div?.classList.contains(DOCUMENTATION_BODY_CLASS)) {
            return;
        }

        // B: Otherwise, navigate in-documentation
        e.preventDefault();
        const url_path = url_prefix + match[3];

        //  Push to history
        history.pushState({ url_path, njdoc: true }, '', url_path);

        //  Navigate
        navigateToPage({ url_path, url_prefix, setUrl, setLoginPath, setOriginalUrl });
    };
}

/**
 * Change the active page in the documentation
 */
function navigateToPage({
    url_path,
    url_prefix,
    setUrl,
    setLoginPath,
    setOriginalUrl,
}: Record<'url_path' | 'url_prefix', string> &
    Record<
        'setUrl' | 'setLoginPath' | 'setOriginalUrl',
        React.Dispatch<React.SetStateAction<string>>
    >): void {
    //  Parse URL data
    const parsed_url = parseUrl(url_path, url_prefix);

    //  Scroll to top?
    const scroll_to_top = !document.location.toString().includes('#');

    //  A: Fade in body-div
    const body_div = getById('body');
    if (body_div) {
        body_div.style.opacity = '0';

        //  Wait for animation
        setTimeout(() => {
            setUrl(parsed_url.url);
            setLoginPath(parsed_url.login_path);
            setOriginalUrl(parsed_url.originalUrl);

            if (scroll_to_top && typeof window !== 'undefined') {
                window.scrollTo(0, 0);
            }

            body_div.style.opacity = '1';
        }, 150);
    }

    //  B: Change immediately
    else {
        setUrl(parsed_url.url);
        setLoginPath(parsed_url.login_path);
        setOriginalUrl(parsed_url.originalUrl);

        if (scroll_to_top && typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }
}

/**
 * Component: Sidebar body
 */
import React, { ReactElement } from 'react';
import {
    IDeveloperDocumentationFile,
    IDocumentationSidebarProps,
    INavigationRouteFile,
    INavigationRouteFolder,
} from '../../../types';
import { headerId } from '../navigation';

/**  Types  */
type IRenderFolderProps = Pick<
    IDocumentationSidebarProps,
    'navigation' | 'url_prefix' | 'is_admin' | 'navigateToDocumentationPage'
> & {
    current_url: string;
};

/**  Globals  */
const EXTRACTION_PATTERN = /^<break|(^#+?) +(.+)/gm;
const CODE_TITLE_PATTERN = /^`(.*)`$/;
const ITALIC_TITLE_PATTERN = /^_(.*)_$/;

/**  Exports  */
export function SidebarBody(props: IDocumentationSidebarProps): ReactElement {
    const {
        navigation,
        url: current_url,
        url_prefix,
        is_admin,
        navigateToDocumentationPage,
    } = props;

    return (
        <div className='sidebar--body'>
            <DocumentationFolder
                navigation={navigation}
                current_url={current_url}
                is_admin={is_admin}
                url_prefix={url_prefix}
                navigateToDocumentationPage={navigateToDocumentationPage}
            />
        </div>
    );
}

/**
 * Component: Folder
 */
function DocumentationFolder({
    navigation,
    current_url,
    url_prefix,
    is_admin,
    navigateToDocumentationPage,
}: IRenderFolderProps): ReactElement | null {
    const { is_root, title } = navigation;

    let folder_class = 'navigation--folder';
    if (is_root) {
        folder_class += ' navigation--folder--root';
    }

    // Hide folder if no pages (i.e. hidden, due to public/private hide)
    if (!navigation.children.length) {
        return null;
    }

    return (
        <div className={folder_class}>
            {/*  Folder header  */}
            {is_root ? null : (
                <div className='navigation--folder--title'>
                    <span>{title}</span>
                </div>
            )}

            {/*  Children  */}
            {navigation.children.map((child: INavigationRouteFolder | INavigationRouteFile) => {
                const { type, page, url } = child;

                //  A: Folder
                if (type === 'FOLDER') {
                    return (
                        <DocumentationFolder
                            key={url}
                            navigation={child as INavigationRouteFolder}
                            current_url={current_url}
                            url_prefix={url_prefix}
                            is_admin={is_admin}
                            navigateToDocumentationPage={navigateToDocumentationPage}
                        />
                    );
                }

                //  B: Pageage
                else if (page) {
                    const is_active = page.url === current_url.replace(/\?.*/, '');

                    return (
                        <div key={url} className='navigation--page'>
                            {/*  Page name  */}
                            <PageTitle
                                key={`page--${page.url}`}
                                page={page}
                                is_active={is_active}
                                url_prefix={url_prefix}
                                is_admin={is_admin}
                                navigateToDocumentationPage={navigateToDocumentationPage}
                            />

                            {/*  Page contents (if active) */}
                            {is_active ? <PageNavigation page={page} /> : null}
                        </div>
                    );
                }

                return null;
            })}
        </div>
    );
}

/**
 * Component: Page title
 */
function PageTitle({
    page,
    is_active,
    is_admin,
    url_prefix,
    navigateToDocumentationPage,
}: {
    page: IDeveloperDocumentationFile;
    is_active: boolean;
    is_admin: boolean;
    url_prefix: string;
    navigateToDocumentationPage: IDocumentationSidebarProps['navigateToDocumentationPage'];
}): ReactElement {
    const { url, is_public, title } = page;

    let wrap_class_name = 'navigation--page--title';
    if (is_active) {
        wrap_class_name += ' navigation--page--title--active';
    }

    let text_class_name = 'navigation--page--title--text';
    if (is_active) {
        text_class_name += ' navigation--page--title--text--active';
    }

    const public_component =
        is_public && is_admin ? (
            <span className='navigation--page--title--public' title='File is publically viewable'>
                Public
            </span>
        ) : null;

    return (
        <div className={wrap_class_name}>
            {/*  Name  */}
            {is_active ? (
                <div className={text_class_name}>
                    <span>{title}</span>
                    {public_component}
                </div>
            ) : (
                <a
                    className={text_class_name}
                    href={`${url_prefix}${url}`}
                    onClick={navigateToDocumentationPage}
                >
                    <span>{title}</span>
                    {public_component}
                </a>
            )}
        </div>
    );
}

/**
 * Component: Page headers
 */
function PageNavigation({ page }: { page: IDeveloperDocumentationFile }): ReactElement | null {
    const { content: input_content } = page;

    /**
     * 1: Get file headers, major breaks
     */
    let headers: Array<{
        n: number;
        title: string;
        is_title_header: boolean;
        is_preceded_by_break: boolean;
    }> = [];

    //  Remove code blocks from content
    const content = input_content.replace(/```[\s\S]+?```/g, '');

    //  Find each header
    let match = EXTRACTION_PATTERN.exec(content);
    let preceded_by_break = false;

    while (match) {
        //  A: Headers
        if (match[1]) {
            const n = match[1].length;
            const title = match[2].trim();

            headers.push({
                n,
                title,
                is_title_header: !headers.length,
                is_preceded_by_break: preceded_by_break,
            });

            preceded_by_break = false;
        }

        //  B: <break>
        else {
            preceded_by_break = true;
        }

        match = EXTRACTION_PATTERN.exec(content);
    }

    //  Filter
    headers = headers.filter(({ n, is_title_header }) => !is_title_header && n && n < 4);

    /**
     * n: Render
     */
    if (!headers.length) {
        return null;
    }

    return (
        <div className='navigation--page--contents'>
            {headers.map(({ n, title, is_title_header, is_preceded_by_break }, index) => {
                //  Don't show title header
                //  Don't show H4
                if (is_title_header || n >= 4) {
                    return null;
                }

                const href = `#${headerId(title)}`;

                let classes = 'navigation--page--title';
                if (is_preceded_by_break) {
                    classes += ` navigation--page--title--break`;
                }

                //  Code header, e.g. `<break />`
                const code_title_match = CODE_TITLE_PATTERN.exec(title);

                //  Italicized header, e.g. _compile.sh_
                const italic_title_match = ITALIC_TITLE_PATTERN.exec(title);

                return (
                    <div key={index} className={classes}>
                        <a
                            href={href}
                            className={`navigation--page--title--text navigation--page--title--text--${n}`}
                        >
                            {code_title_match ? (
                                <code>{code_title_match[1]}</code>
                            ) : italic_title_match ? (
                                <i>{italic_title_match[1]}</i>
                            ) : (
                                title
                            )}
                        </a>
                    </div>
                );
            })}
        </div>
    );
}

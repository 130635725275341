/**
 * Documentation body
 */
import React, { ReactElement } from 'react';
import { DOCUMENTATION_BODY_CLASS } from '../../common/globals';
import { IDocumentationSidebarProps } from '../../types';
import { Markdown as MarkdownClass } from '../common/Markdown';

/**
 * Exports
 */
export function DocumentationBody(props: IDocumentationSidebarProps): ReactElement {
    const {
        ast,
        url,
        pages,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    } = props;

    const Markdown = new MarkdownClass({
        ast,
        API_DOMAIN,
        INTERNAL_OAUTH2_DOMAIN,
        navigateToDocumentationPage,
        url_prefix,
    });

    /**
     * Render
     */
    const page = pages.find(p => p.url === url.replace(/\?.*/, ''));
    if (!page) {
        return (
            <div id='body'>
                <div className='page-404'>
                    <img
                        src='https://cdn.nicejob.co/assets/dropped-cone.svg'
                        alt='Page not found'
                    />
                    <p>Page not found, sorry.</p>
                </div>
            </div>
        );
    }

    return (
        <div id='body' className={DOCUMENTATION_BODY_CLASS}>
            {Markdown.render({ src: page.content })}
        </div>
    );
}

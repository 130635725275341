/**
 * Page: API Documentation
 */
import React, { CSSProperties, ReactElement, useState } from 'react';
import { IDocumentationPageProps } from '../../types';
import { DocumentationSidebar } from '../common/DocumentationSidebar';
import { DocumentationToolbar } from '../common/DocumentationToolbar';
import { NavigateToDocumentationPage } from '../common/navigation';
import { DocumentationBody } from './DocumentationBody';

/**  Globals  */
const FONT_FAMILY = `Inter, Helvetica, sans-serif`;
const ROOT_STYLE: CSSProperties = {
    fontFamily: FONT_FAMILY,
    fontSize: '14px',
    height: '100%',
};

/**  Exports  */
export function DocumentationPage(props: IDocumentationPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */

    return <DocumentationPageContent {...props} />;
}

function DocumentationPageContent(props: IDocumentationPageProps): ReactElement {
    /**
     * State: URL
     */
    const [url, setUrl] = useState(props.url);
    const [login_path, setLoginPath] = useState(props.login_path);
    const [originalUrl, setOriginalUrl] = useState(props.originalUrl);

    /**
     * Methods
     */
    const navigateToDocumentationPage = NavigateToDocumentationPage({
        hostname: props.hostname,
        url_prefix: props.url_prefix,
        url,
        setUrl,
        setLoginPath,
        setOriginalUrl,
    });

    /**
     * Render
     */
    const sidebar_props = {
        ...props,
        url,
        login_path,
        originalUrl,
        navigateToDocumentationPage,
    };

    // @ts-ignore
    delete sidebar_props.page;

    return (
        <div style={ROOT_STYLE}>
            <noscript>Please enable JavaScript to access this page.</noscript>

            {/* Navigation */}
            <DocumentationSidebar {...sidebar_props} />
            <DocumentationToolbar {...sidebar_props} />

            {/* Content */}
            <DocumentationBody {...sidebar_props} />
        </div>
    );
}

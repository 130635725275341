import React, { ReactElement } from 'react';
import { IForgotPasswordPageProps } from '../../types';
import { DevLoginFooter } from './common/DevAuthFooter';
import { DevAuthHeader } from './common/DevAuthHeader';

export function ForgotPasswordPage(props: IForgotPasswordPageProps): ReactElement {
    return (
        <div className='narrow'>
            <DevAuthHeader />

            <div className='block submitted_loading'>
                <div className='card login_card submitted_hide'>
                    <form
                        action='/docs/forgot_password'
                        method='POST'
                        className='submit_exit submitted_disabled'
                    >
                        <p className='invalid'>
                            {`Forgot your password? Enter your email address here, and we'll send you a password reset link.`}
                        </p>

                        <div className='row'>
                            <input
                                className='text_input'
                                required
                                placeholder='Email address'
                                type='email'
                                name='email'
                            />
                        </div>
                        <div className='row submit_row'>
                            <input
                                className='contained_button submit_input'
                                type='submit'
                                value='Submit'
                            />
                        </div>
                    </form>

                    {props.error_message && (
                        <div className='error'>
                            <strong>Error:</strong>
                            <span>{props.error_message}</span>
                        </div>
                    )}
                </div>
            </div>

            <DevLoginFooter />
        </div>
    );
}

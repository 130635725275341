/**
 * Parse URL, for navigation purposes
 */
import { IBasePageProps } from '../types';
import { LOGIN_PATH } from './globals';

export function parseUrl(
    url_path: string,
    url_prefix: string
): Pick<IBasePageProps, 'login_path' | 'url' | 'originalUrl'> {
    return {
        originalUrl: url_path,
        url: url_path.replace(url_prefix, '') || '/',
        login_path: `${LOGIN_PATH}?domain=API&then=${encodeURIComponent(url_path)}`,
    };
}

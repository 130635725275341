import React, { ReactElement } from 'react';
import { IForgotPasswordPageSentProps } from '../../types';
import { DevLoginFooter } from './common/DevAuthFooter';
import { DevAuthHeader } from './common/DevAuthHeader';

export function ForgotPasswordSentPage(props: IForgotPasswordPageSentProps): ReactElement {
    return (
        <div className='narrow'>
            <DevAuthHeader />

            <div className='block submitted_loading'>
                <div className='card login_card submitted_hide'>
                    <div>
                        <p className='invalid'>
                            Done! Check your email inbox for instructions on how to reset your
                            password.
                        </p>
                    </div>
                </div>
            </div>

            <DevLoginFooter />
        </div>
    );
}
